import React, {useState, useEffect, useContext} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from "react-router-dom";
import Session from "../../session";
import { faCalendarCheck, faFax, faCreditCard, faHospital, faAddressBook, faUserCircle, faClock } from "@fortawesome/free-solid-svg-icons";
import { useSmsThreads } from '../../appsync-hooks/smsThreadHook';
import { useFaxes } from '../../appsync-hooks/faxHook';
import {useOrganization} from '../../appsync-hooks/organizationHook';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useAuth0 } from "../../react-auth0-spa";
import { LocalLanguageContext } from '../LocalLanguageProvider'
import {
  Snackbar,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  Badge
} from '@mui/material';
import {
  SupportAgent as SupportAgentIcon,
  Forum as ForumIcon,
  Sms,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: 'whitesmoke',
    minWidth: '30px',
  },
  text: {
    paddingLeft: '0px',
    color: 'whitesmoke',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 'normal',
    fontSize: '14px'
  },
  activeNav: {
    color: 'black',
    "& > *": {
      background: '#47afeb',
    },
  },
  inactiveNav: {
    color: 'black',
    "& > *": {
      '&:hover': {
        backgroundColor: '#47afeb'
      },
    },
  }, 
  listButton: {
    padding: '4px 10px'
  },
  listItemButton:{
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
  snackbarActionButton: {
    marginLeft: '10px',
    color: 'white !important',
    border: '1px solid white !important',
  },
}));

export default function OrgType({twilioSecureMessageUnread, twilioChatUnread}) {
  const [viewAppointment, setViewAppointment] = useState(false);
  const [viewEfax, setViewEfax] = useState(false);
  const [viewSecureMessages, setViewSecureMessages] = useState(false);
  const [viewSMS, setViewSMS] = useState(false);
  const [viewChat, setViewChat] = useState(false);
  const [viewPayment, setViewPayment] = useState(false);
  const [viewTimeClock, setViewTimeClock] = useState(false);
  const [viewPatients, setViewPatients] = useState(false);
  const [viewContacts, setViewContacts] = useState(false);
  const [smsThreads] = useSmsThreads();
  const [newSMSmsg, setNewSMSmsg] = useState(false)
  const [receivedFaxQuery] = useFaxes();
  const [hasNewFaxes, setHasNewFaxes] = useState(false);
  const [userPortalType, setUserPortalType] = useState(null);
  const organization = useOrganization();
  const hasSupportRole = Session.CustomOrgSwitcher;
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const { user } = useAuth0();
  const classes = useStyles();
  const { workerLang } = useContext(LocalLanguageContext);

  const LokalisedContent = (key, fallback) => {
    const translated_string = workerLang[key];
    return translated_string ? translated_string : fallback;
  }

  useEffect(() => {
    if (user) {
      console.log('authUser', user);
      const userRoles = user["https://sbt-dashboard.com/roles"];
      if(userRoles && userRoles.includes("patient")){
        setUserPortalType("patient");
      } else {
        setUserPortalType("admin");
      }
    }
  }, [user])

  useEffect(() => {
    const permissions = user["https://sbt-dashboard.com/Permissions"];
    if (organization?.getOrganization?.orgPlan.length > 0 && permissions) {
      if (organization.getOrganization.orgPlan.includes('efax')
        && permissions.efax.viewable
      ) {
        setViewEfax(true);
      }
      if (organization.getOrganization.orgPlan.includes('appointment')
        && permissions.appointment.viewable
      ) {
        setViewAppointment(true);
      }
      if (organization.getOrganization.orgPlan.includes('secure messaging')
        && permissions.secure_messaging.viewable
      ) {
        setViewSecureMessages(true);
      }
      if (organization.getOrganization.orgPlan.includes('sms')
        && permissions.sms.viewable
      ) {
        setViewSMS(true);
      }
      if (organization.getOrganization.orgPlan.includes('chat')
        && permissions.chat.viewable
      ) {
        setViewChat(true);
      }
      if (organization.getOrganization.orgPlan.includes('payment')
        && permissions.payment.viewable
      ) {
        setViewPayment(true);
      }
      if (organization.getOrganization.orgPlan.includes('time clock')
        && permissions.time_clock.viewable
      ) {
        setViewTimeClock(true);
      }
      if (permissions.patients.viewable) {
        setViewPatients(true);
      }
      if (permissions.contacts.viewable) {
        setViewContacts(true);
      }
    }
    // eslint-disable-next-line
  }, [organization]);

  useEffect(() => {
    let setSmsFlag = 0;
    smsThreads.forEach(x => {
      if (x.newMessageCount){
        setSmsFlag += x.newMessageCount
      }
    });
    if (setSmsFlag > 0) {
      setNewSMSmsg(true)
    } else {
      setNewSMSmsg(false)
    }
  }, [smsThreads]);

  useEffect(() => {
    let setFaxFlag = 0;
    if(receivedFaxQuery.length > 0) {
      receivedFaxQuery.forEach(x => {
        if (!x.isViewed){
          ++setFaxFlag
        }
      });
      if (setFaxFlag > 0) {
        setHasNewFaxes(true);
      } else {
        setHasNewFaxes(false);
      }
    }
  }, [receivedFaxQuery]);


const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  //setChatNotify(false);
  setOpenSnackBar(false);
};

  return (
    <>
    <Snackbar
      open={openSnackBar}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      style={{top:'64px'}}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      
    </Snackbar>
      {(userPortalType !== "patient" && viewAppointment) ? (
        <li>
          <NavLink to="/appointment" className={classes.inactiveNav} activeClassName={classes.activeNav}>
            <ListItem button className={classes.listButton}>
              <ListItemIcon className={classes.icon}>{<FontAwesomeIcon icon={faCalendarCheck} fixedWidth/>}</ListItemIcon>
              <ListItemText 
                disableTypography
                primary={<Typography type="body2" className={classes.text}>Appointment</Typography>} 
              />
            </ListItem>
            
          </NavLink>
        </li>
      ) : (
          ""
      )}

      {(userPortalType !== "patient" && viewEfax) ? (
        <li>
          <NavLink to="/eFax" className={classes.inactiveNav} activeClassName={classes.activeNav}>
            <ListItem button className={classes.listButton}>
              <ListItemIcon className={classes.icon}>{<FontAwesomeIcon icon={faFax} fixedWidth/>}</ListItemIcon>
              <ListItemText 
                disableTypography
                primary={<Typography type="body2" className={classes.text}>eFax</Typography>} 
              />
              <span style={{paddingRight: '10px'}}>
                {hasNewFaxes ? <div style={{backgroundColor: "#47afeb", padding: "6px", borderRadius: '10px'}} /> : ''}
              </span>
            </ListItem>
          </NavLink>
        </li>
      ) : (
          ""
      )}

      {(userPortalType !== "patient" && viewSMS) ? (
        <li>
          <NavLink to="/sms" className={classes.inactiveNav} activeClassName={classes.activeNav}>
            <ListItem button className={classes.listButton}>
              <ListItemIcon 
                className={classes.icon}
              >
                {
                // <Badge badgeContent={4} color="primary">
                  <Sms/>
                // </Badge>
                }
              </ListItemIcon>
              <ListItemText 
                disableTypography
                primary={<Typography type="body2" className={classes.text}>SMS</Typography>} 
              />
              <span style={{paddingRight: '10px'}}>
                {newSMSmsg ? <div style={{backgroundColor: "#47afeb", padding: "6px", borderRadius: '10px'}} /> : ''}
              </span>
            </ListItem>
          </NavLink>
        </li>
        ) : (
          ""
      )}

    {viewChat ? (
      // Staff Chat View
         <li>
         <NavLink to="/chat" className={classes.inactiveNav} activeClassName={classes.activeNav}>
           <ListItem button className={classes.listButton}>
           {(twilioChatUnread > 0) ? <span className='pay-stp'></span>: ''}
             <ListItemIcon className={classes.icon}>{<ForumIcon/>}</ListItemIcon>
             <ListItemText 
               disableTypography
               primary={<Typography type="body2" className={classes.text}>Chat</Typography>} 
             />
              <span style={{paddingRight: '10px'}}>
              </span>
              <Badge
                badgeContent={twilioChatUnread}
                sx={{
                  "& .MuiBadge-badge": {
                    color: "white",
                    backgroundColor: "#6DBFEF",
                  },
                }}
              ></Badge>
           </ListItem>
         </NavLink>
       </li>
        ) : (
          ""
      )}

    {viewSecureMessages ? (
         <li>
         <NavLink to="/securemessage" className={classes.inactiveNav} activeClassName={classes.activeNav}>
           <ListItem button className={classes.listButton}>
            {(twilioSecureMessageUnread > 0) ? <span className='pay-stp'></span>: ''}
             <ListItemIcon className={classes.icon}>{ <VpnKeyIcon/>}</ListItemIcon>
             <ListItemText 
               disableTypography
               primary={<Typography type="body2" className={classes.text}>Secure Message</Typography>} 
             />
              <span style={{paddingRight: '10px'}}>
              </span>
              <Badge
                badgeContent={twilioSecureMessageUnread}
                sx={{
                  "& .MuiBadge-badge": {
                    color: "white",
                    backgroundColor: "#6DBFEF",
                  },
                }}
              ></Badge>
           </ListItem>
         </NavLink>
       </li>
        ) : (
          ""
      )}


      {userPortalType === "patient" ? (
        <>
        <li>
          <NavLink to="/patient/appointments" className={classes.inactiveNav} activeClassName={classes.activeNav}>
            <ListItem button className={classes.listButton}>
              <ListItemIcon className={classes.icon}>{<FontAwesomeIcon icon={faCalendarCheck} fixedWidth/>}</ListItemIcon>
              <ListItemText 
                disableTypography
                primary={<Typography type="body2" className={classes.text}>Appointment</Typography>} 
              />
            </ListItem>
            
          </NavLink>
        </li>
        <li>
          <NavLink to="/patient/chat" className={classes.inactiveNav} activeClassName={classes.activeNav}>

            <ListItem button className={classes.listButton}>
              {(twilioSecureMessageUnread > 0) ? <span className='pay-stp'></span>: ''}

              <ListItemIcon className={classes.icon}>{<VpnKeyIcon/>}</ListItemIcon>
              <ListItemText 
                disableTypography
                primary={<Typography type="body2" className={classes.text}>
                  {LokalisedContent('nav_bar_chat', 'Secure Message')}
                </Typography>} 
              />
              <span style={{paddingRight: '10px'}}>
                {hasNewFaxes ? <div style={{backgroundColor: "#47afeb", padding: "6px", borderRadius: '10px'}} /> : ''}
              </span>
              <Badge
                badgeContent={twilioSecureMessageUnread}
                sx={{
                  "& .MuiBadge-badge": {
                    color: "white",
                    backgroundColor: "#6DBFEF",
                  },
                }}
              ></Badge>
            </ListItem>
          </NavLink>
        </li>
      </>
        ) : (
          ""
      )}

      {(userPortalType !== "patient" && viewPayment) ? (
        <li>
          <NavLink to="/payment" className={classes.inactiveNav} activeClassName={classes.activeNav}>
            <ListItem button className={classes.listButton}>
              {!Session.IsStripeConnect && <span className='pay-stp'></span>}
              <ListItemIcon className={classes.icon}>{<FontAwesomeIcon icon={faCreditCard} fixedWidth/>}</ListItemIcon>
              <ListItemText 
                disableTypography
                primary={<Typography type="body2" className={classes.text}>Payment</Typography>} 
              />
            </ListItem>
          </NavLink>
        </li>
        ) : (
          ""
      )}

      {(userPortalType !== "patient" && viewTimeClock) ? (
        <>
        <li>
          <NavLink to="/timeclock" className={classes.inactiveNav} activeClassName={classes.activeNav}>
            <ListItem button className={classes.listButton}>
              <ListItemIcon className={classes.icon}>{<FontAwesomeIcon icon={faClock} fixedWidth/>}</ListItemIcon>
              <ListItemText 
                disableTypography
                primary={<Typography type="body2" className={classes.text}>Time Clock</Typography>} 
              />
            </ListItem>
          </NavLink>
        </li>
       </>
        ) : (
          ""
      )}

      {(userPortalType !== "patient" && (viewSecureMessages || viewPayment || viewPatients)) ? (
        <li>
          <NavLink to="/patients" className={classes.inactiveNav} activeClassName={classes.activeNav}>
            <ListItem button className={classes.listButton}>
              <ListItemIcon className={classes.icon}>{<FontAwesomeIcon icon={faHospital} fixedWidth/>}</ListItemIcon>
              <ListItemText 
                disableTypography
                primary={<Typography type="body2" className={classes.text}>Patient</Typography>} 
              />
            </ListItem>
          </NavLink>
        </li>
        ) : (
          ""
      )}

      {(userPortalType !== "patient" && (viewSMS || viewEfax || viewContacts)) ? (
        <li>
          <NavLink to="/contacts" className={classes.inactiveNav} activeClassName={classes.activeNav}>

            <ListItem button className={classes.listButton}>
              <ListItemIcon className={classes.icon}>{<FontAwesomeIcon icon={faAddressBook} fixedWidth/>}</ListItemIcon>
              <ListItemText 
                disableTypography
                primary={<Typography type="body2" className={classes.text}>Contacts</Typography>} 
              />
            </ListItem>
          </NavLink>
        </li>
        ) : (
          ""
      )}

      {userPortalType !== "patient" && <li>
        <NavLink to="/profile" className={classes.inactiveNav} activeClassName={classes.activeNav}>

            <ListItem button className={classes.listButton}>
              <ListItemIcon className={classes.icon}>{<FontAwesomeIcon icon={faUserCircle} fixedWidth/>}</ListItemIcon>
              <ListItemText 
                disableTypography
                primary={<Typography type="body2" className={classes.text}>Account</Typography>} 
              />
            </ListItem>

        </NavLink>
      </li>}

      {userPortalType === "patient" && <li>
        <NavLink to="/patient/profile" className={classes.inactiveNav} activeClassName={classes.activeNav}>

            <ListItem button className={classes.listButton}>
              <ListItemIcon className={classes.icon}>{<FontAwesomeIcon icon={faUserCircle} fixedWidth/>}</ListItemIcon>
              <ListItemText 
                disableTypography
                primary={<Typography type="body2" className={classes.text}>
                  {LokalisedContent('nav_bar_profile', 'Profile')}
                  </Typography>} 
              />
            </ListItem>

        </NavLink>
      </li>}

      {hasSupportRole ?
        <li>
          <NavLink to="/support" className={classes.inactiveNav} activeClassName={classes.activeNav}>
            <ListItem button className={classes.listButton}>
              <ListItemIcon className={classes.icon}>{<SupportAgentIcon/>}</ListItemIcon>
              <ListItemText
                disableTypography
                primary={<Typography type="body2" className={classes.text}>Support</Typography>} 
              />
            </ListItem>
          </NavLink>
        </li> : ''}
    </>
  )
}
