import {useMemo} from 'react';
import axios from 'axios';
import { API_URL } from "../config";
import Session from "../session";

export class RestAPI {
  constructor({accessToken}) {
    this.client = axios.create({
      baseURL: API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: 'json',
    });

    // Global error handler
    return new Proxy(this, {
      get: (target, key) => {
        return (...args) =>
          Reflect.get(target, key).apply(this, args).catch(error => {
            console.log('error in api js', error);
            throw error;
          });
      },
    });
  }

  async generateTwilioVideoLink(payload) {
    const response = await this.client.post(`/v3/twilio-video/generateTwilioVideoLink`, payload);

    return response;
  }

  async sendUnauthTwilioVideoLink(payload) {
    const response = await this.client.post(`/v3/twilio-video/sendUnauthTwilioVideoLink`, payload);
    console.log('generate token response in api.js', response);
    return response;
  }

  async generateAgoraLink(payload) {
    const response = await this.client.post(`/v3/agora/generateVideoLink`, payload);

    return response;
  }

  async generateAgoraToken(payload) {
    const response = await this.client.post(`/v3/agora/generateToken`, payload);
    console.log('generate token response in api.js', response);
    return response;
  }

  async generateMessagingAgoraToken(payload) {
    const response = await this.client.post(`/v3/agora/generateMessagingToken`, payload);
    console.log('generate token response in api.js', response);
    return response;
  }

  async createStaffUser(payload) {
    const response = await this.client.post(`/v3/orgs/${Session.OrganizationId}/staff`, payload);

    return response;
  }

  async editStaff(payload) {
    const response = await this.client.put(`/v3/orgs/${Session.OrganizationId}/staff/${Session.UserId}`, payload);
    
    return response;
  }

  async editOtherStaffUser(payload, user) {
    const response = await this.client.put(`/v3/orgs/${Session.OrganizationId}/staff/${user}`, payload);
    
    return response;
  }

  async updateOrg(payload) {
    const response = await this.client.put(`/v3/orgs`, payload);
    
    return response;
  }

  async updateWebToken(payload) {
    await this.client.put(`/v3/tokens/web/${payload}`);
  }


  async removeWebToken(auth0Id) {
    await this.client.put(`/v3/orgs/removeWebtoken`, { auth0Id: auth0Id });
  }

  async changePassword(payload) {
    // this.logAction('Changing password');
    const response = await this.client.put(`/v3/users/password/update`, payload);
    console.log('response for password change =======>', response);
    return response;
  }

  async resetPassword(payload) {
    // this.logAction(`User with ${payload} is resetting their password`);
    const response = await this.client.post(`/v3/users/password/${payload}/reset`);
    console.log('response for resetting password =====>', response);
  }

  async deleteStaffUser(payload) {
    const response = await this.client.delete(`/v3/orgs/${Session.OrganizationId}/staff/${payload}`);

    return response;
  }

  async deletePatient(payload) {
    const response = await this.client.delete(`/v3/patients/${payload}`);

    return response;
  }

  async deleteContact(payload) {
    const response = await this.client.delete(`/v3/orgs/${Session.OrganizationId}/contacts/${payload}`);

    return response;
  }
  
  async getPutObjectURL(payload) {
    const response = await this.client.get(`/v3/files/signedurl/put/sbt?key=${payload}`);

    return response;
  }

  async submitFax(payload) {
    const response = await this.client.post(`/v3/faxes/`, payload);

    return response;
  }
  
  async getFetchObjectURL(payload) {
    const response = await this.client.get(`/v3/files/signedurl/get?key=${payload}`);

    return response;
  }

  async getFetchObjectURLwithBucket(payload, bucket) {
    const response = await this.client.get(`/v3/files/signedurl/get?key=${payload}&bucket=${bucket}`);

    return response;
  }

  async getReceivedFaxURL(payload) {
    const response = await this.client.get(`/v3/faxes/${payload}`);

    return response;
  }

  async updateFaxStatus(faxSid) {
    const response = await this.client.put(`/v3/faxes/updateFaxStatus/${faxSid}`);

    return response;
  }

  async addContact(payload) {
    const response = await this.client.post(`/v3/orgs/${Session.OrganizationId}/contacts`, payload);

    return response;
  }

  async updateContact({payload, user}) {
    const response = await this.client.put(`/v3/orgs/${Session.OrganizationId}/contacts/${user}`, payload);

    return response;
  }

  async addPatient(payload) {
    const response = await this.client.post('/v3/patients' , payload);

    return response;
  }

  async notifyPatient(user) {
    const response = await this.client.get(`/v3/patients/${user}/notify`);
    return response;
  }

  async editPatient({payload, user}) {
    console.log(payload)
    console.log(user)
    const response = await this.client.put(`/v3/patients/${user}` , payload);
    
    return response;
  }
  
  async paymentCenterPay(payload) {
    const response = await this.client.post('/v3/payments', payload);
    
    return response;
  }

  async paymentCenterRefund({payload, user}) {
    const response = await this.client.post(`/v3/payments/${user}/refund`, payload);

    return response;
  }

  async scheduleAppointment(payload) {
    const response = await this.client.post(`/v3/appointments`, payload);

    return response;
  }

  async reScheduleAppointment(payload, id) {
    const response = await this.client.put(`/v3/appointments/${id}`, payload);

    return response;
  }

  async deleteAppointment(id) {
    const response = await this.client.delete(`/v3/appointments/${id}`);

    return response;
  }

  async endAppointmentTwilioExecutions(appointmentId) {
    const response = await this.client.delete(`/v3/appointments/executions/${appointmentId}`);

    return response;
  }

  async sendSmsToTwilio(payload) {
    const response = await this.client.post('/v3/messages/send/twilio', payload);

    return response;
  }

  async fetchAttachmentUrl(payload) {
    const response = await this.client.get(`/v3/files/signedurl/get?key=${payload}`);

    return response;
  }

  async putAttachmentUrl(payload) {
    const response = await this.client.get(`/v3/files/signedurl/put/sbt?key=${payload}`);

    return response;
  }

  async linkS3Object({name, ulid, accessibleOrgs, meta = {}, uploader}) {
    const response = await this.client.post('/v3/files/upload', {
      fileName: name,
      fileUlid: ulid,
      accessibleOrgs,
      createdTimestamp: Math.round(new Date().getTime() / 1000),
      meta,
      uploader,
    });
    console.log(response)
    return response;
  }

  async unlinkAndDeleteS3Object(ulid) {
    await this.client.delete('/v3/files/', {
      data: {
        fileUlid: ulid,
      },
    });
  }

  async updateS3Object({fileName, fileUlid, accessibleOrgs, meta = {}}) {
    const res = await this.client.put('/v3/files/', {
      fileName,
      fileUlid,
      accessibleOrgs,
      meta,
      ...Object
    });

    return res;
  }

//   async logAction(action) {
//     await this.client.post('/logs/', {action});
//   }

  async getTimesheetByUlid({ ulid }) {
    const res = await this.client.get(`/v3/timesheet/${ulid}`);

    return res.data;
  }

  async getTimesheetsByOrg() {
    const res = await this.client.get(`/v3/timesheet/org/${Session.OrganizationId}`);

    return res.data;
  }

  async getTimesheetsByUser({ ulid }) {
    console.log(ulid)
    const response = await this.client.get(`/v3/timesheet/user/${ulid}`);
    console.log(response.data)
    return response.data;
  }

  async getTimeSheetHistory(entryId){
    console.log('getting history')
    console.log(entryId)
    const res = await this.client.get(`/v3/timesheethistory/${entryId}`);
    console.log(res)
    return res.data;
  }

  async createTimesheetEvent({ location, entryId }) {
    const options = {
      location,
      entryId,
    };
    const res = await this.client.post(`/v3/timesheet/clockin`, options);
    return res.data;
  }

  async addTimesheetEvent(eventKey, { location, entryId }) {
    const res = await this.client.post(`/v3/timesheet/${entryId}/${eventKey}`, {location});
    return res.data;
  }

  async putTimesheetExpense({ entryId, amount, fileUlids = []}) {
    const res = await this.client.post(`/v3/timesheet/${entryId}/expense`, {
      amount,
      fileUlids: fileUlids,
    });


    return res.data;
  }

  async putTimesheetNote({ entryId, note}) {
    const res = await this.client.post(`/v3/timesheet/${entryId}/note`, {
      note,
    });

    return res.data;
  }

  async putTimesheetMileage({ entryId, mileage }) {
    const res = await this.client.post(`/v3/timesheet/${entryId}/mileage`, {
      mileage,
    });

    return res.data;
  }

  async submitTimesheet({ entryId, location }) {
    const res = await this.client.post(`/v3/timesheet/${entryId}/submitday`, {
      location,
    });

    return res;
  }

  async editTimeSheetEntry({entryId, body}){
    const res = await this.client.put(`/v3/timesheet/${entryId}/timesheetentry`, {
      body,
    });

    return res;
  }

  async createTimeSheetEntry(payload){
    const res = await this.client.post(`/v3/timesheet/entry`, {
      payload,
    });

    return res;
  }

  async createCustomer(payload) {
    const res = await this.client.post(`/v3/orgs/billing/create`, payload);
    return res;
  }

  async getPlans() {
    const res = await this.client.get(`/v3/orgs/planList`);
    return res;
  }

  async UpdateOrgPlan(payload) {
    const res = await this.client.post(`/v3/orgs/${Session.OrganizationId}/updateOrgPlan`, payload);
    return res;
  }


  async checkIfUserExists(payload) {
    const res = await this.client.post(`/v3/checkUserExists`, payload);
    return res;
  }

  async checkCouponCode(payload) {
    const res = await this.client.post(`/v3/checkCouponCode`, payload);
    return res;
  }

  async createOrg(payload) {
    const res = await this.client.post(`/v3/orgs`, payload);
    return res;
  }

  async archiveOrg(payload) {
    const res = await this.client.delete(`/v3/orgs`, payload);
    return res;
  }


  async multiProductSubscription(payload) {
    const res = await this.client.post(`/v3/orgs/billing/multiProductSubscription`, payload);
    return res;
  }
  async UpdateOrgSubscription(payload) {
    const res = await this.client.post(`/v3/orgs/${Session.OrganizationId}/UpdateOrgSubscription`, payload);
    return res;
  }
  async buyTelnyxNumber() {
    const res = await this.client.post(`/v3/orgs/${Session.OrganizationId}/AddTelnyxNumber`);

    return res;
  }

  async deleteEfaxNumber(payload) {
    const response = await this.client.delete(`/v3/${Session.OrganizationId}/faxes/${payload}`);

    return response;
  }

  async getSignedPublicS3Key(key) {
    const res = await this.client.get(`/v3/files/signedurl/put/public?key=${key}`);

    return res;
  }

  async accountLinkStripeConnect(stripeId) {
    const res = await this.client.post(`/v3/orgs/${Session.OrganizationId}/stripe/accountLink`, {
      "stripeId": stripeId,
    });

    return res.data;
  }

  async generateStripeExpressLogin(stripeId) {
    const res = await this.client.post(`/v3/orgs/${Session.OrganizationId}/stripe/generateExpressLoginLink`, {
      "stripeId": stripeId,
    });

    return res.data;
  }

  async linkStripeConnect() {
    const res = await this.client.post(`/v3/orgs/${Session.OrganizationId}/stripe/connect`, {
    });

    return res.data;
  }

  async getReceiptData(organizationId, chargeId) {
    const res = await this.client.get(`/v3/getReceipt?orgId=${organizationId}&chargeId=${chargeId}`)

    return res.data;
  }

  async getEnvironmentConfigs() {
    const res = await this.client.get(`/v3/admin/environment`)
    return res;
  }

  async sendEmailReceipt(email, link, organizationId, chargeId, name){
    const payload = {
      email,
      link,
      chargeId,
      name
    }
    const res = await this.client.post(`/v3/orgs/${organizationId}/notification/email`, payload)

    return res
  }

  async sendSmsReceipt(mobileNum, link, organizationId){
    const payload = {
      mobileNum: mobileNum,
      link: link
    }
    const res = await this.client.post(`/v3/orgs/${organizationId}/notification/sms`, payload)

    return res
  }

  async getOrgSwitcherData(){
    const res = await this.client.get(`/v3/orgs/orgSwitcher`)

    return res
  }

  async getBilling(organizationId){
    const res = await this.client.get(`/v3/orgs/${organizationId}/getOrgBills`)
    return res
  }

  async getInvoiceDetails(invoiceId, organizationId){
    const res = await this.client.get(`/v3/orgs/${organizationId}/getInvoiceDetails/${invoiceId}`)
    return res
  }

  async getServices(organizationId){
    const res = await this.client.get(`/v3/orgs/${organizationId}/getOrgServices`)
    return res
  }

  async updateOrgSource(payload){
    const res = await this.client.post(`/v3/orgs/billing/updateSource`, payload)
    return res
  }

  async getProratedDue(organizationId){
    const res = await this.client.get(`/v3/orgs/${organizationId}/getProratedDue`)
    return res
  }

  async createInstantInvoice(organizationId){
    const res = await this.client.get(`/v3/orgs/${organizationId}/createDueAmountInvoice`)
    return res
  }

  async getSubscriptionDetail(organizationId){
    const res = await this.client.get(`/v3/orgs/${organizationId}/getSubscriptionDetail`)
    return res
  }

  async getToken(user){
    const res = await this.client.get(`/v3/users/${user}/getTwilioToken`)
    return res
  }
  async createBanner(payload){
    const res = await this.client.post(`/v3/banner`, payload)
    return res
  }

  async deleteBanner(id){
    const res = await this.client.delete(`/v3/banner/${id}`)
    return res
  }

  async getTwilioSecureToken(user){
    const res = await this.client.get(`/v3/users/${user}/getTwilioSecureToken`)
	  return res
	}

  async createNewConversation(orgUlid, patientUlid, email){
    const payload = {
      createdBy: email
    }
    const res = await this.client.post(`/v3/orgs/${orgUlid}/createNewConversation/${patientUlid}`,payload);
	  return res
	}
  async getFeatureFlags(){
    const res = await this.client.get(`/featureflags`);
	  return res
	}
  // async getAllTwilioUsers(){
  //   const res = await this.client.get(`/v3/users/getTwilioUsers`);
	//   return res
	// }
}

export const useAPI = () => {
  const accessToken = Session.Token;
  const api = useMemo(() => new RestAPI({accessToken}), [accessToken]);

  return api;
};
