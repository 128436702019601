import React, { Suspense, lazy, useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, BrowserRouter as Router, Redirect } from "react-router-dom";
import {isMobile} from 'react-device-detect';
import {
  IconButton,
  CircularProgress,
  Alert as MuiAlert,
  Menu,
  MenuItem,
  Backdrop,
  Button,
  Snackbar
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {MoreVertOutlined as MoreVertOutlinedIcon} from '@mui/icons-material';
import history from './utils/history';
import Session from "./session";
import { useAuth0 } from "./react-auth0-spa";
import TopBar from './components/constant/topBar';
import SideNav from './components/constant/sideNav';
import Footer from './components/constant/footer';
import BottomNav from './components/constant/bottomNav';
import {useSmsThreads} from './appsync-hooks/smsThreadHook';
import {useOrgPayments} from './appsync-hooks/paymentsHook';
import {useOrgPayouts} from './appsync-hooks/payoutsHook';
import {useFaxes} from './appsync-hooks/faxHook';
import {useOrganization} from './appsync-hooks/organizationHook';
import {useAppointments} from './appsync-hooks/appointmentsHook';
import { Firebase } from "./firebaseContext";
import ErrorBoundary from './utils/error-boundary';
import { LocalLanguageContext } from './components/LocalLanguageProvider';
import {useTwilioConversationClient} from './appsync-hooks/twilioConversation/clientInit';
import './styleOverrides.css';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SignUp = lazy(() => import("./views/SignUp"));
const Payment = lazy(() => import("./views/Payment"));
const Profile = lazy(() => import("./views/Profile"));
const PatientAppointments = lazy(() => import("./views/patientAppointments/PatientAppointments"));
const PatientProfile = lazy(() => import("./views/PatientProfile"));
const PatientChatMobile = lazy(() => import("./views/PatientChatMobile"));
const PatientChatDesktop = lazy(() => import("./views/PatientChatDesktop"));
const Contacts = lazy(() => import("./views/Contacts"));
const Patients = lazy(() => import("./views/Patients"));
const Efax = lazy(() => import("./views/Efax"));
const SMS = lazy(() => import("./views/SMS"));
const Receipt = lazy(() => import("./views/Receipt"));
const Appointment = lazy(() => import('./views/Appointment'));
const StripeConnect = lazy(() => import("./views/StripeConnect"));
const PrivateRoute = lazy(() => import("./components/PrivateRoute"));
const Home = lazy(() => import("./views/Home"));
const CardManagment = lazy(() => import('./views/CardManagment'));
const Support = lazy(() => import("./views/Support"));
const TimeClock = lazy(() => import('./views/TimeClock'));
const InternalChat = lazy(() => import('./views/InternalChat'));
const SecureChat = lazy(() => import('./views/SecureChat'));
const UploadFileStream = lazy(() => import('./views/UploadFileStream'));
const AccountLinkCompleted = lazy(() => import('./components/payment/accountLinkComplete'));
const BannerController = lazy(() => import('./components/banners/BannerController'));

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  root: { 
    display: 'flex',
    flexDirection: 'column',
    
    paddingBottom: '0px',
    height: '100vh',
  },
  top: {
    order: 0,
    zIndex: 1299
  },
  topLogOut: {
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: 103,
  },
  center:{
    order: 1,
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    paddingBottom: '0px',
    backgroundColor: 'white',
    overflow: 'hidden'
  },
  sideBar: {
    order: 0,
  },
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    paddingBottom: '0px',
  },
  centerContent: {
    overflowY: 'auto',
    height: '100%',
    width: '100%'
  },
  bottomNavBar: {
    position: 'fixed',
    bottom: 0,
    width: '100%'
  },
  bottom: {
    order: 1,
    alignSelf: 'center',
    width: '100%',
    padding: '0px',
  },
  snackbarActionButton: {
    marginLeft: '10px',
    color: 'white !important',
    border: '1px solid white !important',
  },
}));

const App = () => {
  const [user, setUser] = useState(null);
  const [useremail, setUserEmail] = useState('');
  const [userPortalType, setUserPortalType] = useState(null);
  const [permissions, setPermissions] = useState({
    appointment: {viewable: false},
    payment: {viewable: false},
    secure_messaging: {viewable: true},
    sms: {viewable: false},
    chat: {viewable: false},
    efax: {viewable: false},
    patients: {viewable: false},
    contacts: {viewable: false},
    time_clock: {viewable: false}
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [hasNavBar, setHasNavBar] = useState(false);
  const [hasBottomNavBar, setHasBottomNavBar] = useState(false);
  const [hasTopLogOut, setHasTopLogOut] = useState(false);
  const [anchorElLogOut, setAnchorElLogOut] = useState(null);
  const { isAuthenticated, user: authUser, logout } = useAuth0();
  const [smsThreads, threadsLoaded] = useSmsThreads();
  const orgPayments = useOrgPayments();
  const orgPayouts = useOrgPayouts();
  const [receivedFaxQuery, sentFaxQuery] = useFaxes();
  const organization = useOrganization();
  const classes = useStyles();
  const { workerLang } = useContext(LocalLanguageContext);
  const firebaseMessaging = Firebase();
  const openLogOut = Boolean(anchorElLogOut);
  const [twilioConversationClient, twilioChat, twilioChatUnread,
    twilioOrgConversationClient, twilioSecureMessage, twilioSecureMessageUnread, 
    onMessageAddedHandler, resetUnread] = useTwilioConversationClient()
  const hasSupportRole = Session.CustomOrgSwitcher;
  useAppointments();

  const LokalisedContent = (key, fallback) => {
    const translated_string = workerLang[key];
    return translated_string ? translated_string : fallback;
  };

  useEffect(() => {
    if(firebaseMessaging){
      setOpenSnackBar(true);
    }
  }, [firebaseMessaging]);

  useEffect(() => {
    if (authUser) {
      console.log('authUser', authUser);
      setPermissions(authUser["https://sbt-dashboard.com/Permissions"]);
      const userRoles = authUser["https://sbt-dashboard.com/roles"];
      if(userRoles && userRoles.includes("patient")){
        console.log('patient login')
        setUserPortalType("patient");
      } else {
        setUserPortalType("admin");
      }
    }
  }, [authUser]);

  useEffect(() => {
    setUser(Session.UserName);
    // eslint-disable-next-line
  }, [user, Session.UserName]);

  useEffect(() => {
    setUserEmail(Session.UserEmail);
    // eslint-disable-next-line
  }, [useremail, Session.UserEmail]);

  useEffect(() => {
    if (isMobile) return;
    const script = document.createElement('script');
    script.src = 'https://js-na1.hs-scripts.com/21260082.js';
    script.async = true;
    script.type = 'text/javascript';
    document.body.appendChild(script);
  }, []);
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    //setChatNotify(false);
    setOpenSnackBar(false);
  };

  const handleClickLogOut = (event) => {
    setAnchorElLogOut(event.currentTarget);
  };

  const handleCloseLogOut = () => {
    setAnchorElLogOut(null);
    logout({ returnTo: 'https://examroomlive.com/patients' });
  };

  return (
    <ErrorBoundary isUserAuthenticated={isAuthenticated}>
      <Snackbar
        open={openSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        {
            <Alert 
              onClose={handleClose} 
              severity="info" 
              sx={{ width: '100%' }} 
              style={{}}
              action={
                <Button variant='outlined' className={classes.snackbarActionButton} onClick={() => {
                  if(firebaseMessaging?.body === 'Telehealth'){
                    window.open(firebaseMessaging?.path, "_blank");
                  } else {
                    window.location.href = firebaseMessaging?.path
                  }
                }}>
                  View
                </Button>
              }
            >
              {firebaseMessaging?.title || 'New Notification'}
            </Alert>
        }
      </Snackbar>
      <Router history={history} >
        <Suspense 
          fallback={
            <Backdrop
              className={classes.backdrop}
              open={true}
            >
              <CircularProgress />
            </Backdrop>
          }
        >
          <div className={classes.root}>

            {
              hasNavBar
                && 
              <div id='SBTTopBar' className={classes.top}>
                <TopBar userPortalType={userPortalType} twilioConversationClient={twilioConversationClient}/>
              </div>
            }

            {
              isAuthenticated 
                &&
              userPortalType === "admin" 
                &&
              <BannerController/>
            }

            {
              hasTopLogOut
                && 
              <div id='SBTTopBar' className={classes.topLogOut}>
                <IconButton
                  onClick={handleClickLogOut}
                  size="large"
                  aria-controls={openLogOut ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openLogOut ? 'true' : undefined}
                >
                  <MoreVertOutlinedIcon fontSize="inherit" style={{paddingBottom: 3, color: 'white'}}/>
                </IconButton>
                <Menu
                  anchorEl={anchorElLogOut}
                  id="account-menu"
                  open={openLogOut}
                  onClose={() => {
                    setAnchorElLogOut(null);
                  }}
                >
                  <MenuItem onClick={handleCloseLogOut}>
                    {LokalisedContent('nav_bar_logout', 'Log Out')}
                  </MenuItem>
                </Menu>
              </div>
            }
          
            <div className={classes.center}>
              
              {
                hasNavBar
                  && 
                <div id='SBTSideNav' className={classes.sideBar}>
                  <SideNav twilioChat={twilioChat} twilioChatUnread={twilioChatUnread} twilioSecureMessageUnread={twilioSecureMessageUnread} />
                </div>
              }

              <div className={classes.centerContainer}>
              
                <div id='SBTCenterContent' className={classes.centerContent}>

                  {
                    userPortalType === null
                      &&
                    <Switch>
                      <Route exact path="/signup" component={SignUp} />
                      <Route path="/receipt" component={Receipt}/>
                      <Route
                        path="*"
                        render={() => {
                          return (
                            isAuthenticated ? 
                              <Redirect exact to="/profile" />
                            : <Route path="/" component={Home} />
                          )
                        }}
                      />
                    </Switch>
                  }

                  {
                    userPortalType === "admin" 
                      &&
                    <Switch>
                      {permissions.payment.viewable ? <PrivateRoute path="/accountlinkcomplete" component={AccountLinkCompleted} /> : ''}
                      {permissions.appointment.viewable ? <PrivateRoute path="/appointment" render={() => <Appointment setHasNavBar={setHasNavBar}/>} /> : ''}
                      {permissions.payment.viewable ? <PrivateRoute path="/payment" render={() => <Payment orgPayments={orgPayments} orgPayouts={orgPayouts} setHasNavBar={setHasNavBar}/>} /> : ''}
                      {permissions.secure_messaging.viewable ? <PrivateRoute path="/securemessage" render={() => <SecureChat resetUnread={resetUnread} onMessageAddedHandler={onMessageAddedHandler} twilioSecureMessage={twilioSecureMessage} twilioConversationClient={twilioOrgConversationClient} setHasNavBar={setHasNavBar}/>} />: '' }
                      {permissions.sms.viewable ? <PrivateRoute path="/sms" render={() => <SMS smsThreads={smsThreads}  setHasNavBar={setHasNavBar} threadsLoaded={threadsLoaded}/>} /> : ''}
                      {permissions.chat.viewable ? <PrivateRoute path="/chat" render={() => <InternalChat resetUnread={resetUnread} onMessageAddedHandler={onMessageAddedHandler} twilioChat={twilioChat} twilioConversationClient={twilioConversationClient} setHasNavBar={setHasNavBar}/>} /> : ''}
                      {permissions.efax.viewable ? <PrivateRoute path="/eFax" render={() => <Efax receivedFaxes={receivedFaxQuery} sentFaxes={sentFaxQuery} orgDetails={organization} setHasNavBar={setHasNavBar}/>}/> : ''}
                      {permissions.patients.viewable ? <PrivateRoute path="/patients" render={() => <Patients setHasNavBar={setHasNavBar} AuthUser={authUser}/>} /> : ''}
                      {permissions.contacts.viewable ? <PrivateRoute path="/contacts" render={() => <Contacts setHasNavBar={setHasNavBar} AuthUser={authUser}/>} /> : ''}
                      <PrivateRoute path="/stripeconnect" component={StripeConnect} />
                      {permissions.time_clock.viewable ? <PrivateRoute path="/timeclock" render={() => <TimeClock setHasNavBar={setHasNavBar}/>} /> : ''}

                      <PrivateRoute path="/profile" render={() => <Profile setHasNavBar={setHasNavBar} AuthUser={authUser}/>} />

                      <Route path="/card" component={CardManagment} />
                      <Route path="/login" component={Home} />
                      <Route path="/streamupload" component={UploadFileStream} />
                      <Route path="/signup" component={SignUp} />

                      <Route path="/receipt" component={Receipt}/>
                      {hasSupportRole &&
                        <PrivateRoute path="/support" render={() => <Support setHasNavBar={setHasNavBar} />}/>
                      }
                      <Route
                        path="/"
                        render={() => {
                          return (
                            isAuthenticated ? 
                              <Redirect exact to="/profile" />
                            : <Route path="/" component={Home} />
                          )
                        }}
                      />
                      
                    </Switch>
                  }

                  {
                    userPortalType === "patient"
                      && 
                    <Switch>
                      <PrivateRoute 
                        path="/patient/appointments" 
                        render={() => <PatientAppointments setHasBottomNavBar={setHasBottomNavBar} setHasTopLogOut={setHasTopLogOut} setHasNavBar={setHasNavBar}/>}
                      />
                      <PrivateRoute 
                        path="/patient/profile" 
                        render={() => <PatientProfile setHasBottomNavBar={setHasBottomNavBar} setHasTopLogOut={setHasTopLogOut} setHasNavBar={setHasNavBar}/>}
                      />
                      <PrivateRoute 
                        path="/patient/chat/:reschedule?" 
                        render={() => isMobile ? 
                          <PatientChatMobile resetUnread={resetUnread} onMessageAddedHandler={onMessageAddedHandler} twilioSecureMessage={twilioSecureMessage} twilioConversationClient={twilioConversationClient} setHasBottomNavBar={setHasBottomNavBar} setHasTopLogOut={setHasTopLogOut} /> : 
                          <PatientChatDesktop resetUnread={resetUnread} onMessageAddedHandler={onMessageAddedHandler} twilioSecureMessage={twilioSecureMessage} twilioConversationClient={twilioConversationClient} setHasNavBar={setHasNavBar}/>
                        } 
                      />
                      <Route
                        path="/"
                        render={() => {
                          return (
                            isAuthenticated ? 
                              <Redirect exact to="/patient/profile" />
                            : <Route path="/" component={Home} />
                          )
                        }}
                      />
                      
                    </Switch>
                  }

                </div>

                {
                  hasBottomNavBar 
                    && 
                  isMobile 
                    && 
                  userPortalType === "patient"
                    && 
                  <div className={classes.bottomNavBar}>
                    <BottomNav />
                  </div>
                }

                {
                  !isMobile
                    &&
                  <div id='SBTFooter' className={classes.bottom}>
                    <Footer />
                  </div>
                }

              </div>
            </div>
          </div>
        </Suspense>
      </Router>
    </ErrorBoundary>
  );
}

export default withRouter(App);
