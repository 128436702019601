import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom"
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "./react-auth0-spa";
import history from "./utils/history";
import { ApolloProvider, ApolloClient } from '@apollo/client';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { AUTH_TYPE } from 'aws-appsync';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';
// import { datadogRum } from '@datadog/browser-rum';
import { ContactProvider } from './context/context';
import { LocalLanguageProvider } from './components/LocalLanguageProvider'
import Logger from './utils/logger';
import { LicenseInfo } from '@mui/x-license-pro';

Logger();

LicenseInfo.setLicenseKey(
  process.env.REACT_APP_MUI_LICENSE,
);

// datadogRum.init({
//   applicationId: process.env.REACT_APP_DATADOG_APP_ID, 
//   clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
//   site: 'datadoghq.com', 
//   service:'examroomlive',
//   version: "1.0.0",
//   env: process.env.REACT_APP_STAGE,
//   sampleRate: 100, 
//   trackInteractions: true
// });
  
// datadogRum.startSessionReplayRecording();

const url = process.env.REACT_APP_AMPLIFY_ENDPOINT;
const region = process.env.REACT_APP_AMPLIFY_REGION;
const auth = {
    type: AUTH_TYPE.API_KEY,
    apiKey: process.env.REACT_APP_AMPLIFY_API_KEY,
};

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth })
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const theme = createTheme({
  palette: {
    primary: {
      // Main blue color for the app. This is set as the main primary color.
      main: '#47afeb',
      dark: '#005184',
      contrastText: '#fff',
    },
    success: {
      main: '#10D236',
      dark: '#0a8938',
      contrastText: '#fff',
    },
    error: {
      main: '#E22C56',
      dark: '#971533',
      contrastText: '#fff',
    },
  },
});

ReactDOM.render(
  <ApolloProvider client={client}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        useRefreshTokens= {true}
      >
        <LocalLanguageProvider>
          <Router history={history}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <ContactProvider>
                    <App />
                </ContactProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </Router>
        </LocalLanguageProvider>
        {/* </SnackbarProvider> */}
      </Auth0Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
